import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import ItemsData from './ItemsData';
import { Footer } from './Footer';
import WEB_APP_ENDPOINT from './web_app_endpoint';
import { ItemWithInfo } from './ItemWithInfo';

let N_ROWS = ItemsData.length

const Ranking = () => {
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [filteredItems, setFilteredItems] = useState([])
  const [totalVotes, setTotalVotes] = useState(-1)

  const intRankToStrRank = (intRank) =>
  {
    const thresh = 500;
    if (intRank <= thresh)
    {
      return intRank;
    }
    return ">" + thresh
  }

  const handlePartyFilterChange = (event) => {
    let newFilterValue = event.target.value;
    setPartyFilter(newFilterValue);
    let filteredItems_ = newFilterValue === "" ? items : items.filter(item => item.props.itemData.party === newFilterValue)
    filteredItems_ = filteredItems_.map((item, ind) =>
      {let rank = newFilterValue == ""
        ? item.props.rank
        //: intRankToStrRank(parseInt(ind + 1)) + ", tot. " + intRankToStrRank(item.props.rank);
        : intRankToStrRank(parseInt(ind + 1));
        return <Item
          itemData={item.props.itemData}
          key={rank + ind}
          rank={rank}
          showParty={true}
          smallVersion={true}
      />
      })
    setFilteredItems(filteredItems_);
  };

  useEffect(() => {
    // Load initial value on component mount
    updateVotes();
  }, []);

  function updateVotes() {
    setLoading(true)

    fetch(WEB_APP_ENDPOINT + "?n_rows=" + N_ROWS)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.log(response.status)
          throw new Error('Error retrieving value');
        }
      })
      .then((data) => {
        // setVotes(data.values);
        setTotalVotes(data.total_votes)
        console.log("total_votes: " + data.total_votes)
        let sortedKeys_ = Object.entries(data.values)
        .sort((a, b) => b[1] - a[1])
          .map(([key]) => parseInt(key) + 1);
        // setSortedKeys(sortedKeys_);
        let notFoundItemData = { "id": -1, "name": "", "imageUrl": "" }
        let sortedItems_ = sortedKeys_.map((itemKey, ind) => 
        { 
          let itemData = ItemsData.find(item => item.id == itemKey) ?? notFoundItemData
          return <ItemWithInfo
            itemData={itemData}
            key={itemKey}
            rank={intRankToStrRank(parseInt(ind + 1))}
            showParty={false}
            onClick={() => {}}
            isSelected={false}
            handleSelect={() => {}}
            showInfoText={false}
            smallVersion={true}
        />})
        setItems(sortedItems_)
        setFilteredItems(sortedItems_)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error retrieving value:', error);
      });
  }

  
  const darkerEuBlue = "#001069";

  return (
    <div>
      <div className="content" >
        {loading ?
        <CircularProgress style={{ marginTop: "100px" }}/>
        : 
        <div style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            flexDirection: "column"
          }}>
          <div className="total-votes-text">{totalVotes > 0 ? "Totalt antal röster: " + totalVotes : ""}</div>
          {filteredItems}
          <div style={{height: "80px"}} />
        </div>
        }
      </div>
      <Footer/>
    </div>
  );
};

export default Ranking;