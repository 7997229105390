const ItemsData = [
  {
    "id": 1,
    "firstName": "Karin",
    "lastName": "Enström",
    "party": "M",
    "gender": "W",
    "imageUrl": "./images/Karin Enström.jpg",
    "infoText": `<a href="https://www.barometern.se/familj/karin-enstrom-tjejen-som-gjorde-lumpen/" target="_blank">Karin "Kajsa" Enström – Tjejen som gjorde lumpen</a>, har varit central inom det Moderata samlingspartiet sedan hon tog över posten som försvarsminister 2012. Att hon är tjej är bra – Sverige skickar nämligen bara tjejer till EU-kommissionen, lite som när vi under 10-talet bara skickade killar till Eurovision. Det kallar vi jämställdhet.
    
    Enström har ministererfarenheten, men är inte minister i den nuvarande regeringen. Med det sagt är hon en väldigt tung (obs: kroppsneutralt) kandidat. Kristersson förlorar inget viktigt statsråd om han skickar henne till Bryssel, men kan vinna en viktig kommissonärspost.
    
    Som försvarsminister visade Karin Enström å andra sidan svårigheter i att uppfatta vad som utgör en demokrati. Hur är det, brukar en demokrati inte ha fria val och utfärda straffet tusen piskrapp mot regeringskritiker? Eller är det en icke-demokrati, eller rakt av en diktatur? Måste man ens välja?
    
    Om hon skulle råka få posten som kommissionär för demokrati och demografi kan det därmed bli tufft för henne. Det bådar emellertid gott för hennes möjligheter att få sitta med ungrarna i kantinen.
    
    Ett större problem än hennes bristande kunskap om statsskick är faktumet att hon är nepobaby. Men Moderaternas svågerpolitiska idéprogram innehåller i nuläget inga policyförslag som förhindrar en kvinna, vars bror är nationell säkerhetsrådgivare och BFF med statsministern, att utnämnas till posten som kommissionär. Huruvida man får vara faster till MSU:s ordförande är fortfarande upp till tolkning.`
  },
  {
    "id": 2,
    "firstName": "Carl",
    "lastName": "Bildt",
    "party": "M",
    "gender": "M",
    "imageUrl": "./images/Carl Bildt.webp",
    "infoText": `Hallänning, svensk, kommissionär?
 
    Göran Persson må ha sagt att Carl Bildt är “så jävla dålig va”, men han är sannerligen både en Europavän och en internationellt erkänd diplomat. Bildt har lång erfarenhet av regeringsarbete som både stats- och utrikesminister samt bloggare, och skulle nog kunna vara med och tävla om en eftertraktad kommissionärspost.
    
    Hösten 2025 ska dock Carl Bildt vittna i rättegången om Lundin Oil, nej förlåt Lundin Petroleum, nej fan Lundin Energy, vilket kanske kan ställa till det schemamässigt för honom. 
    
    Annars kanske han får lyssna på Göran Perssons förslag: Luftfartsverket, generaldirektörsjobb, attraktivt för någon som är teknikglad. Rent allmänt ambassadörsjobb? Chef för europeiska investeringsbanken?
    `
  },
  {
    "id": 3,
    "firstName": "Maria",
    "lastName": "Malmer Stenergard",
    "party": "M",
    "gender": "W",
    "imageUrl": "./images/Maria Malmer.png",
    "infoText": `Jurist, systemvetare, kommissionär?

    Kronofogden MMS blev 2022 utnämnd till migrationsminister efter två mandatperioder i riksdagen. Hon har gjort sig känd som en stor utvisningsförespråkare, men vill hon själv bli utvisad till Benelux? När hon fick frågan svarade hon att det var för tidigt att säga, vilket på politiska går att översätta till “ja, om jag får frågan”.
    
    Det blev i alla fall mycket häng med Ylva Johansson under förhandlingen av EU:s migrationspakt. Kan hon ha fått blodad tand av Berlaymont? Eller är hon för viktig för regeringen Ulf Kristersson I?
    `
  },
  {
    "id": 4,
    "firstName": "Anders",
    "lastName": "Borg",
    "party": "M",
    "gender": "M",
    "imageUrl": "./images/Anders Borg.jpeg",
    "infoText": `Anders Borg är inte bara en dikt av Jenny Högström, Anders Borg är också tidigare politiker. 2006 till 2014 var han Sveriges finansminister och fick internationellt beröm för hur han hanterade finanskrisen. Kanske kan det vara en fördel när det blir dags att förhandla om vilken kommissionärspost Sverige ska få. 

    Borgs exfru och nuvarande flickvän Dominika Peczynski, internationell popstar, otrohetsentreprenör och liberal riksdagskandidat, har sannerligen en europeisk energi. Men kan det faktum att hennes band Army of Lovers spelat på <a href="https://www.expressen.se/noje/army-of-lovers-pengaklirr-hemliga-spelningarna-pa-ryska-lyxbrollop-enorma-summor-/" target="_blank">ryska oligarkbröllop</a> kanske kan ligga Borg i fatet? Eller är det Army of Lovers låt “Love is blue” med ukrainska artisten Olya Polyakova som ger Borg rollen som Ursula von der Leyens nya försvarskommissionär?
    
    Annars kan det finnas en post ledig hos European Union Aviation Safety Agency (EASA) som reglerar luftfarkoster såsom drönare, flygplan och helikoptrar?
    `
  },
  {
    "id": 5,
    "firstName": "Jessika",
    "lastName": "Roswall",
    "party": "M",
    "gender": "W",
    "imageUrl": "./images/Jessika Roswall.jpg",
    "infoText": `Den nuvarande EU-ministern Jessika Roswall är främst känd för att inte vara känd. När regeringen tillträdde hamnade hon på sista plats av de mest kända statsråden, och tyvärr tycks situationen kontraintuitivt ha förvärrats efter Sveriges ordförandeskap i EU:s ministerråd.

    Roswall har fått både ris och ros av personer i hennes närhet efter det svenska ordförandeskapet. Enligt uppgifter ska hon själv ha älskat de sex månaderna som Sverige fick leda ministerrådets arbete från Arlandastad.
    
    En stor nackdel är, förutom hennes namnokunnighet, att hon saknar längre erfarenhet av regeringsarbete. Hon riskerar att få en av alla kommissionärsposter som har ansvar för
    “demokrati, kultur och värderingar samt teknisk innovation i närområdet med särskilt fokus på unga och framtidsfrågor samt ansvar för partnerskap i grannområdet”.
    `
  },
  {
    "id": 6,
    "firstName": "Gunnar",
    "lastName": "Hökmark",
    "party": "M",
    "gender": "M",
    "imageUrl": "./images/Gunnar Hökmark.jpg",
    "infoText": `En “<a href="https://www.aftonbladet.se/nyheter/a/1krPGJ/attacken-alla-missade-javla-skithog" target="_blank">jävla skithög</a>” enligt Marita Ulvskog (S). En erfaren moderat politiker enligt andra. Hökmark är en Brysselveteran som suttit i Europaparlamentet i hela 15 år (mellan åren 2004 och 2019), dessförinnan var han partisekreterare i gamla Moderaterna. 

    Numera är Hökmark konsult på Kreab, en PR-byrå känd för att ta emot de kunder som andra byråer nekar på grund av etiska riktlinjer.
    
    Även höken Hökmark saknar ministererfarenhet. Men visst känns det som att han har? Kanske kan han gå långt på enbart vibe.
    `
  },
  {
    "id": 7,
    "firstName": "Anna Maria",
    "lastName": "La Cucaracha Bildt",
    "party": "partipolitisk agnostiker",
    "gender": "W",
    "imageUrl": "./images/Anna Maria.jpeg",
    "infoText": `Det är fredag och du ska laga lyxmiddag. På köksbänken står ett paket grädde, bredvid ligger 500 gram spagetti, en förpackning bacon och en påse frysta ärtor. Allora.

    Vattenångan immar dina glasögon när du häller av pastan i durkslaget. Du sparar inget pastavatten. Gräddsåsen puttrar i stekpannan. Snart ska du kombinera pastan och såsen för att skapa en delikat carbonara. Men innan du hunnit röra samman rätten knackar det på dörren. Du går för att öppna. 
    
    I trapphuset står hon. Det silvriga håret står rakt upp och hennes kinder är rödflammiga av ilska. Det blixtrar i hennes ögon när hon utbrister:
    
    “Var är guancialen? Den äggulan? Pecorino? Svarta peppar? Det här du har gjorde är inga riktigt carbonara. Det är den matfusk. Basta!”
    
    Du förstår direkt. Den sextioåriga kvinnan framför dig är den gastronomiska entreprenören bakom italian tradition dot com och hon säger basta till matfusket!
    
    La Cucaracha, född i Rom och engagerad i det moderata samlingspartiet, kryssade in sig i Europaparlamentet i valet 2009 genom låten “<a href="https://www.youtube.com/watch?v=xGuHziKTYso" target="_blank">Kryssa Anna Maria</a>” (🎵Vi kryssar Anna Maria, vi kryssar Anna Maria, till Europaparlamentet, vi kryssar Anna Maria, jag brinner för ett Europa som kan göra ännu mer för fred🎵).
    
    Erfarenhet av EU? Absolut! God relation till Moderata samlingspartiet? Vi lämnar det osagt.
    `
  },
  {
    "id": 8,
    "firstName": "Fredrik",
    "lastName": "Reinfeldt",
    "party": "M",
    "gender": "M",
    "imageUrl": "./images/Fredrik Reinfeldt.jpeg",
    "infoText": `Den tidigare statsministern och moderatledaren, nuvarande ordföranden i svenska fotbollförbundet, har gjort samma resa som Bruno Mars och Arla mjukglass i tetrapak, det vill säga, varit väldigt populär kring förra decennieskiftet, men blivit något vi helst undviker i dag. 

    Det som talar emot: en rivalitet med utväljaren, nuvarande statsminister Ulfk, som varat sedan innan Sveriges ens blev medlemmar i EU.
    
    Det som talar för: exakt samma rivalitet med ovan nämnda nuvarande statsminister. Ulfk har möjligheten att med politiska medel förhindra införandet av VAR i svensk fotboll genom att tillsätta Reinfeldt som kommissionär. Detta hade dels gynnat hans relation med dottern, bajaren och division 3-spelaren Siri Ed Kristersson, eventuellt hade det även höjt opinionssiffrorna bland supportrar, men framförallt hade det försämrat hans redan dåliga relation med sin företrädare.
    
    Om Reinfeldt hade utnämnts till kommissionär hade han fått ännu mer poliseskort än tidigare, och det är något som representanter från det Sverigevänliga Tidöpartiet inte hade gillat. Han om någon borde tvingas sitta kvar utan något som helst polisskydd.
    `
  },
  {
    "id": 9,
    "firstName": "Ebba",
    "lastName": "Busch",
    "party": "KD",
    "gender": "W",
    "imageUrl": "./images/Ebba Busch.jpg",
    "infoText": `Det ryktas om att Kristdemokraternas partiledare, närings- och energiminister Ebba Busch, är riktigt sugen på att byta KN-departementet mot Bryssel. Det ryktas till och med om att Margaux Dietz bror redan ringt upp en äldre man i Woluwe-Saint-Pierre och övertalat honom att sälja sitt hus.

    “Det var tre kristdemokrater som grundade EU” brukar EB fd T säga. Att det är just partibeteckningen kristdemokrat som gör att Moderaterna inte kommer vilja tillsätta henne är därför ironiskt.
    `
  },
  {
    "id": 10,
    "firstName": "Tobias",
    "lastName": "Billström",
    "party": "M",
    "gender": "M",
    "imageUrl": "./images/Tobias Billström.gif",
    "infoText": `Tobias Billström har varit med länge. Nu senast har han varit med i följetongen Natoprocessen där han har spelat rollen som svensk utrikesminister som försökte charmera karaktären Erdoğan. Dessförinnan var han en språklig pionjär när han (redan) 2013 pratade om invandring i volym. 

    Bisexuelle Billström skulle bli den första bisexuella kommissionären. En bicon i Berlaymont!
    
    En annan fördel för Billström om han byter jobb är att kvarglömda resehandlingar inte kommer vara ett problem med EU:s fria rörlighet. Frågan är bara om han eller hans chef vill att han byter jobb.
    `
  },
  {
    "id": 11,
    "firstName": "Sten",
    "lastName": "Tolgfors",
    "party": "M",
    "gender": "M",
    "imageUrl": "./images/Sten Tolgfors.jpg",
    "infoText": `Tolgfors har en historia av att ställa upp när det behövs. 2006 tog han över som handelsminister efter att Maria Borelius fått en snigel på ögat och betalat sin barnflicka svart. 2007 blev han försvarsminister efter att Mikael Odenberg lämnat regeringen i protest mot den nedbantade försvarsbudgeten.

    2012 blev det dock Sten “Tomhylsan” Tolgfors tur att avgå när Sveriges Radio avslöjade att FOI haft planer på att bygga en vapenfabrik i Saudiarabien. Sedan dess har han jobbat på PR-byrån Rud Pedersen och som landshövding i Västra Götaland. Kanske är Tolgfors sugen på att lämna länsresidenset i Göteborg och ta sitt pick och pack till Europas Göteborg (nej, det är inte London).
    `
  },
  {
    "id": 12,
    "firstName": "La Gunilla",
    "lastName": "Carlsson",
    "party": "M",
    "gender": "W",
    "imageUrl": "./images/Gunilla Carlsson.webp",
    "infoText": `La-la-la, la-la Gunilla
    She won't bend, no
    she won't break
    La-la-la, la-la Gunilla
    EU-kommissionen make a storm but
    Gunilla Carlsson won't shake.
    
    Före detta EU-parlamentariker, riksdagsledamot och biståndsminister. Numera är Carlsson chef för den 0,88%-stora biståndsmyndigheten Sida.
    `
  },
  {
    "id": 13,
    "firstName": "Anna",
    "lastName": "Kinberg Batra",
    "party": "M",
    "gender": "W",
    "imageUrl": "./images/Anna Kinberg Batra.png",
    "infoText": `Oavsett vem regeringen till sist utser som kommissionär lär denne bli en av få av de tjugosju som faktiskt talar engelska. Men med AKB får vi en kommissionär som dessutom talar två av Belgiens tre officiella språk – franska och nederländska (det tredje är bokstavskombinationer). 

    Det som riskerar att äventyra en kommissionärspost för Kinberg Batra är framförallt det faktum att hon nyligen tillträdde som landshövding för länet där de som är smartare än lantisar bor. Och landsbygdsborna bleknar i jämförelse när man stöter på belgarna.
    `
  },
  {
    "id": 14,
    "firstName": "Christofer",
    "lastName": "Fjellner",
    "party": "M",
    "gender": "M",
    "imageUrl": "./images/Christofer Fjellner.jpg",
    "infoText": `Moderaternas oppositionsråd i Stockholm, Tiktok-törstfångaren Christofer Fjellner, var EU-parlamentariker mellan 2004 och 2019. Under hans senare tid i parlamentet blev han mycket uppskattad av alla svenskar, förutom möjligen de närmaste medarbetarna, genom att göra om Moderaternas kansli till snusshop och fylla samtliga kylskåp och kontorslådor med Göteborgs Rapé. Med Fjellner som kommissionär öppnas alltså möjligheterna upp för Ursula von der Leyen att kunna arbeta, bo och köpa snus utan att lämna byggnaden. Ett sant ABC-område. Enjoy capitalism!`
  }
];

export default ItemsData;