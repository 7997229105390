import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './Navbar';
import HomeAllCandidates from './HomeAllCandidates';
import Ranking from './Ranking';
import ItemsData from './ItemsData';
import WEB_APP_ENDPOINT from './web_app_endpoint';

function AppRouter() {
  // const [usedItemsCount, setUsedItemsCount] = useState(2)
  const [items, setItems] = useState([...ItemsData])
  // const [itemsToDisplay, setItemsToDisplay] = useState([])
  
  function shuffleList(list) {
    const shuffledList = [...list];
  
    for (let i = shuffledList.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledList[i], shuffledList[j]] = [shuffledList[j], shuffledList[i]];
    }
  
    return shuffledList;
  }

  function GetHash(year, month, day, hours, minutes) {
    return (year*2*month*11*day) % 37 + (13*hours*17*minutes) + (11*hours) % 17 - (193 * minutes) % 101 + (39*minutes*17*day) % 91 + (year*hours) % minutes
  }

  function GetTimeHash() {
    var date = new Date()
    var newDate = new Date(date);
    var year = newDate.getFullYear();
    var month = newDate.getMonth() + 1;
    var day = newDate.getDate();
    var hours = newDate.getHours() + 1;
    var minutes = newDate.getMinutes() + 1;

    return GetHash(year, month, day, hours, minutes)
  }

  function sumCharCodeOfString(str) {
    let sum = 0;
    for (let i = 0; i < str.length; i++) {
        let mult = 3 + (i % 9)
        sum += mult * str.charCodeAt(i);
    }
    return sum;
  }

  async function addVote(vote_yes_id) {
    var timeHash = GetTimeHash()

    // Get the user's IP address using an external service (optional)
    const user_ip_response = await fetch("https://api64.ipify.org?format=json");
    const user_ip_data = await user_ip_response.json();
    var user_ip = user_ip_data.ip;
    // console.log("ip: " + user_ip)
    // console.log("val before mod: " + user_ip.length * sumCharCodeOfString(user_ip))
    var ip_int = (user_ip.length * sumCharCodeOfString(user_ip)) % 51109;

    var indexMult = [1.2, 2.7, 3.6, 4.1, 6.1];
    var randomIndex = Math.floor(Math.random() * indexMult.length);
    var vote_no_id = (vote_yes_id + 3) * Math.floor(Math.pow(indexMult[randomIndex], 1.5)) + 17;

    fetch(WEB_APP_ENDPOINT + "?id_yes=" + vote_yes_id + "&id_no=" + vote_no_id + "&ask=" + timeHash + "&qp=" + ip_int,
    {
      redirect: "follow",
      method: 'POST',
      headers: {
        "Content-Type": "text/plain;charset=utf-8",
      },
    })
      .then((response) => {
        if (response.ok) {
          // console.log('Value increased successfully');
        } else {
          console.error('Error increasing value:', response.status);
        }
      })
      .catch((error) => {
        console.error('Error increasing value:', error);
      });
  }

  const handleVote = async (id) => {
    addVote(id)

    // let newUsedItemsCount = usedItemsCount + 2
    // setUsedItemsCount(newUsedItemsCount)
    // if (newUsedItemsCount >= items.length - 1)
    // {
    //   var oldItems = items.filter((i) => !itemsToDisplay.includes(i))
    //   oldItems = shuffleList(oldItems)
    //   oldItems.push(itemsToDisplay[0])
    //   oldItems.push(itemsToDisplay[1])
    //   setItems(oldItems);
    //   setUsedItemsCount(2)
    // }
    // setItemsToDisplay(items.slice(newUsedItemsCount - 2, newUsedItemsCount))
  }

  useEffect(() => 
    {
      // let shuffled = shuffleList(items)
      // setItems(shuffled)
      setItems(items);
    }
  , [])

  return (
    <Router basename={process.env.PUBLIC_URL} style={{ margin: 0 }}>
      <Navbar/>
      <Switch>
        <Route exact path="/" render={() => <HomeAllCandidates allItems={items} handleVote={handleVote} />} />
        <Route path="/ranking" component={Ranking}/>
      </Switch>
    </Router>
  );
}

export default AppRouter;
