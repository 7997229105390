import { Tooltip } from '@mui/material';
import React from 'react';

export const VoteButton = ({nameOfVotee, onClick, isDisabled, tooltip}) => {
  const getStringFromVotee = () => nameOfVotee == null ? "" : " på " + nameOfVotee;

  return (
    <Tooltip title={isDisabled ? tooltip : ""} placement="right">
      <span>
        <div className={`vote-button ${isDisabled ? 'disabled' : ''}`}
            onClick={onClick}
            style={{ fontWeight: getStringFromVotee() == "" ? "normal" : "bold" }}>
          {"Rösta" + getStringFromVotee() +"!"}
        </div>
      </span>
    </Tooltip>
  );
};