import React, { useEffect, useState } from 'react';
import { ItemWithInfo } from './ItemWithInfo';
import { VoteButton } from './VoteButton';
import { Footer } from './Footer';
import { CircularProgress } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

const HomeAllCandidates = ({allItems, handleVote}) => {
  const [loading, setLoading] = useState(true)
  const [showVoteText, setShowVoteText] = useState(false);
  const [isClickable, setIsClickable] = useState(true);
  const [buttonTooltip, setButtonTooltip] = useState("");
  
  const [selectedItemId, setSelectedItemId] = useState(null);

  const [isInfoExpanded, setIsInfoExpanded] = useState(false);
  
  const selectCandidateToVote = "Välj en kandidat för att kunna rösta"
  const timeoutMessage = "Vänta lite innan du kan rösta igen"

  const toggleInfoExpanded = () => {
    setIsInfoExpanded(!isInfoExpanded);
  };

  const handleSelect = (itemId) => {
    setSelectedItemId(itemId === selectedItemId ? null : itemId);
    if (buttonTooltip != timeoutMessage) {
      setButtonTooltip(selectCandidateToVote)
    }
  };

  const getSelectedItem = () => {
    if (selectedItemId === null) {
      return null;
    }
    var foundItem = allItems.find(item => item.id === selectedItemId);
    if (foundItem){
      return foundItem;
    }
    return null;
  }

  const getFullName = (item) => {
    if (item === null) {
      return null
    }
    return item.firstName + " " + item.lastName
  }

  const handleVoteWithClickability = () => {
    if (!isClickable) {
      return;
    }

    setSelectedItemId(null);

    // Disable clickability
    setIsClickable(false);
    setButtonTooltip(timeoutMessage);

    // Enable clickability after 300ms
    setTimeout(() => {
      setIsClickable(true);
      setButtonTooltip(selectCandidateToVote);
    }, 5000);

    // Handle the click logic
    handleVote(selectedItemId);

    setShowVoteText(true);
    setTimeout(() => {
      setShowVoteText(false);
    }, 20000);
  }

  useEffect(() => {
    // Load initial value on component mount
    setButtonTooltip(selectCandidateToVote);
    setLoading(false);
  }, []);

  const voteText = "Tack för din röst. Kom ihåg att även rösta i valet till Europaparlamentet den 9 juni!"
  const howToVote = `Här på issionar.com kan du rösta på den person som du anser vara bäst lämpad som EU-kommissionär. Kandidaterna nedan är en sammanställning av de som det har spekulerats om i media.

Läs mer om kandidaterna och lägg din röst nedan genom att scrolla längst ner och klicka 'Rösta' efter att du valt den du vill se som kommissionär.`
  const disclaimer = `Belly Button Gazette och issionar.com arbetar endast i rådgivande syfte.
De oansvariga utgivarna för issionar.com kommer lämna över röstlängden till regeringen under en ceremoni den 10 juni.`
  const aboutIssionar = `Först ska kommissionsordförande utnämnas av Europeiska rådet och godkännas av Europaparlamentet. Helst ska ordförande komma från den största partigruppen i Europaparlamentet (spitzenkandidat-systemet). Därefter kommer ländernas regeringar att välja ut sina kommissionärer. 

Vilken portfölj vår kommissionär kommer att få beror på vilken bakgrund vår kandidat har. Det är fördelaktigt om kommissionären har lång erfarenhet, helst i ministerroller. 
  
Vem regeringen planerar att utse är ännu okänt, men många namn har cirkulerat i media. Då det är den nuvarande regeringen som ansvarar för utnämningen antas Sveriges nästa kommissionär att tillhöra ett Tidöparti. Många lyfter kandidater från Moderaterna i och med att ingen moderat hittills varit kommissionär. 
  
Kommissionen är den enda EU-institution som kan initiera lagförslag och har därmed stor makt – men eftersom posterna tillsätts av respektive lands regering, går det inte att rösta på en enskild kommissionär. För att demokratisera processen presenterar Belly Button Gazette därför denna valplattform.
`
const euYellow = "#DDBB00";

  return (
    <div className="content" >
      <div className='home-page-text'>
        Välj din EU-kommissionär
      </div>
      <div className='home-page-explanation' style={{ paddingBottom: 0 }}>
        <div style={{ height: isInfoExpanded ? "auto" : "200px", overflow: "hidden", cursor: "pointer" }} onClick={toggleInfoExpanded}>
          <div className='home-page-explanation-header'>
            Hur det funkar
          </div>
          <div className='home-page-explanation-text'>
            {howToVote}
          </div>
          <div className='home-page-explanation-header'>
            Hur utses en EU-kommissionär?
          </div>
          <div className='home-page-explanation-text'>
            {aboutIssionar}
            <div style={{ paddingTop: "15px", fontSize: "10pt", fontStyle: "italic" }}>
              {disclaimer}
            </div>
          </div>
        </div>
        <div style={{ height: "1.5rem", width: "100%", display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={toggleInfoExpanded}>
          <IconButton onClick={(e) => {
            e.stopPropagation();
            toggleInfoExpanded();
          }
          }
            >
            {isInfoExpanded ? <ExpandLess style={{ color: euYellow }}/> : <ExpandMore style={{ color: euYellow }}/>}
          </IconButton>

        </div>
      </div>
      {loading ?
      <CircularProgress/> :
      // <div className='item-pair'>
      <div className='item-list'>
        {allItems.map(item => 
          <ItemWithInfo
            key={item.id}
            itemData={item}
            showParty={true}
            isSelected={selectedItemId === item.id}
            handleSelect={() => handleSelect(item.id)}
        />)}
        {showVoteText ?
        <div className='thanks-for-voting'>
          {voteText}
        </div> : <></>
        }
        <div style={{ marginTop: "3rem", marginBottom: "1rem" }}>
          <VoteButton nameOfVotee={getFullName(getSelectedItem())}
                    isDisabled={selectedItemId === null}
                    onClick={handleVoteWithClickability}
                    tooltip={buttonTooltip}/>
        </div>
      </div>}
      <Footer/>
    </div>
  );
};


export default HomeAllCandidates;