import { IconButton } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import React, { useState } from 'react';
import DOMPurify from 'dompurify';

const whiteUrl = "./images/white.jpeg"

export const itemToStr = (params) => {
    let fullName = params.item.firstName + " " + params.item.lastName
    let party = (params.item.party == "" || !params.showParty) ? "" : " (" + params.item.party + ")"
    return fullName + party
}

export const ItemWithInfo = ({
  itemData, itemKey, rank, showParty, onClick,
  isSelected, handleSelect, showInfoText=true, smallVersion=false
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isInfoExpanded, setIsInfoExpanded] = useState(false);

  const toggleInfoExpanded = () => {
    setIsInfoExpanded(!isInfoExpanded);
  };
;
  const defaultInfoText = "ERROR";
  const infoText = itemData.infoText ?? defaultInfoText;
  const sanitizedInfoText = DOMPurify.sanitize(infoText);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const euYellow = "#DDBB00";
  const euBlue = "#001489";

  var maxWidthImg = smallVersion ? "300px" : "500px"
  var maxWidthText = smallVersion ? "320px" : "100%"
  var fontSize = smallVersion ? "16pt" : "30pt"
  return (
  <div className={`item-container ${isSelected ? 'selected' : ''}`}
        key={itemKey}
        style={{
          borderRadius: "10px",
          maxWidth: maxWidthText,
          padding: "8px"
        }}
        onClick={handleSelect}>
    <img 
      src={itemData.imageUrl ?? whiteUrl}
      onClick={onClick}
      onLoad={handleImageLoad}
      style={{
        display: "flex",
        justifySelf: "center",
        maxWidth: maxWidthImg,
        height: 'auto',
      }}
    />
    <div className='item-text'
      style={{
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
        textAlign: "center",
        maxWidth: maxWidthText,
        fontSize: fontSize,
        fontWeight: 500
        }}>
      {rank !== undefined && `(${rank}) `} 
      {imageLoaded ? itemToStr({ "item": itemData, "showParty": showParty }) : ""}
    </div>
    {showInfoText ?
    <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
      {
        !isInfoExpanded ? 
        (<IconButton onClick={(e) => {
          e.stopPropagation();
          toggleInfoExpanded();
        }
        }
          style={{ marginTop: "-1rem", marginBottom: "-1rem", color: isSelected ? euBlue : euYellow }}>
          <ExpandMore/>
        </IconButton>)
        : <></>
      }
      {isInfoExpanded && (
        <div className='item-description-box'
              style={{ display: "flex", flexDirection: "column", alignItems: "center",  }}>
          <div dangerouslySetInnerHTML={{ __html: sanitizedInfoText }}/>
          <div className='expand-button-div' style={{ marginTop: "1rem" }}>
            <IconButton onClick={(e) => {
              e.stopPropagation()
              toggleInfoExpanded()
            }}
            style={{ marginTop: "-1rem", marginBottom: "-1rem", color: euBlue }}>
              <ExpandLess/>
            </IconButton>
          </div>
        </div>
      )}
    </div> : <></>}
  </div>
  );
};