import React from 'react';

export const Footer = () => {
  return (
    <div className="footer">
      <FooterContent/>
    </div>
  );
};

export const FooterContent = () => {
  return (
    <p style={{ fontSize: "10pt" }}>
      Oansvarig utgivare: Politikermat AB | politikermat@gmail.com | <a href="https://www.instagram.com/politikermat">Instagram</a>
    </p>
  );
};
